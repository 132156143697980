<template>
    <span class="profile-badge">
        
        <span :title="'ID ' + customer.id + ( customer.group ? ' - ' + customer.group.name : '')" class="badge badge-pill bg-dark" :style="customer.group && customer.group.color ? 'border: 5px solid '+  customer.group.color : ''">{{ customer.external_id }}</span>
    </span>
    
</template>

<script>
import { defineComponent } from 'vue'



export default defineComponent({
    
  name: 'ProfileBadge',
  

  props: {
     customer: {
         type: Object,
         required: true,
     },
    
  },
  mounted: function(){
      if( this.code )
        this.image_url = this.base_url + this.code + '.JPG';
  }
  
})
</script>

