<template>
    <span class="catalog-image">
        <img v-if="image_url" :src="image_url" :alt="alt" :class="'img-fluid ' + imgclass" @error='handleImageNotFound'>
        <img :src="no_image" :class="'img-fluid ' + imgclass" v-else/>
    </span>
    
</template>

<script>
import { defineComponent } from 'vue'
import no_image from '../assets/images/no-image.png';


export default defineComponent({
    
  name: 'CatalogImage',
  
  data: function(){
      return {
          base_url: 'https://docs.coccatoemezzetti.com/catalogo_img/img_art/',
          image_url: null,
          no_image: no_image,
      }
  },
  props: {
     code: {
         type: String,
         required: true,
     },
     alt: {
         type: String,
         required: false,
     },
     imgclass: {
         type: String,
         required: false,
         default: '',
     },
  },
  mounted: function(){
      if( this.code )
        this.image_url = this.base_url + this.code + '.JPG';
  },
  methods: {
      handleImageNotFound: function(){
          this.image_url = null;
          // todo: gestire un placeholder?
      }
  }
  
})
</script>