import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// import Toast from "vue-toastification";
import "bootstrap"

const app = createApp(App);

app.config.performance = true;
app.use(store).use(router).mount('#app');

/*
app.use(Toast, {
    transition: "Vue-Toastification__slideBlurred",
    maxToasts: 20,
    newestOnTop: true,
    position: "bottom-right",
    timeout: 3000,
});
*/

